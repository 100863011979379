var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "사고방지대책 및 원인분석" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.disabled,
                            expression: "!disabled",
                          },
                        ],
                        attrs: {
                          url: _vm.updateUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.accidentInfo,
                          mappingType: "PUT",
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveAccident,
                          btnCallback: _vm.saveCallback,
                        },
                      }),
                      _c("c-appr-btn", {
                        ref: "appr-btn",
                        attrs: {
                          name: "danger-journal-appr-btn",
                          editable: _vm.editable,
                          approvalInfo: _vm.approvalInfo,
                        },
                        on: {
                          beforeApprAction: _vm.saveDataAppr,
                          callbackApprAction: _vm.approvalCallback,
                          requestAfterAction: _vm.requestAfterAction,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        label: "설비",
                        name: "equip",
                      },
                      model: {
                        value: _vm.accidentInfo.equip,
                        callback: function ($$v) {
                          _vm.$set(_vm.accidentInfo, "equip", $$v)
                        },
                        expression: "accidentInfo.equip",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        label: "관리",
                        name: "management",
                      },
                      model: {
                        value: _vm.accidentInfo.management,
                        callback: function ($$v) {
                          _vm.$set(_vm.accidentInfo, "management", $$v)
                        },
                        expression: "accidentInfo.management",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        label: "교육",
                        name: "education",
                      },
                      model: {
                        value: _vm.accidentInfo.education,
                        callback: function ($$v) {
                          _vm.$set(_vm.accidentInfo, "education", $$v)
                        },
                        expression: "accidentInfo.education",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        label: "환경",
                        name: "enviroment",
                      },
                      model: {
                        value: _vm.accidentInfo.enviroment,
                        callback: function ($$v) {
                          _vm.$set(_vm.accidentInfo, "enviroment", $$v)
                        },
                        expression: "accidentInfo.enviroment",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        label: "개인",
                        name: "individual",
                      },
                      model: {
                        value: _vm.accidentInfo.individual,
                        callback: function ($$v) {
                          _vm.$set(_vm.accidentInfo, "individual", $$v)
                        },
                        expression: "accidentInfo.individual",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        label: "기타",
                        name: "etc",
                      },
                      model: {
                        value: _vm.accidentInfo.etc,
                        callback: function ($$v) {
                          _vm.$set(_vm.accidentInfo, "etc", $$v)
                        },
                        expression: "accidentInfo.etc",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        rows: 5,
                        label: "사고원인분석",
                        name: "shortPlan",
                      },
                      model: {
                        value: _vm.accidentInfo.shortPlan,
                        callback: function ($$v) {
                          _vm.$set(_vm.accidentInfo, "shortPlan", $$v)
                        },
                        expression: "accidentInfo.shortPlan",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        rows: 5,
                        label: "개선대책",
                        name: "longPlan",
                      },
                      model: {
                        value: _vm.accidentInfo.longPlan,
                        callback: function ($$v) {
                          _vm.$set(_vm.accidentInfo, "longPlan", $$v)
                        },
                        expression: "accidentInfo.longPlan",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "c-table",
        {
          ref: "tableImpr",
          attrs: {
            title: "개선 목록",
            columns: _vm.gridImpr.columns,
            data: _vm.accidentInfo.accidentImproveModelList,
            gridHeight: _vm.gridHeight,
            merge: _vm.gridImpr.merge,
            selection: "multiple",
            rowKey: "causesNo",
            usePaging: false,
            hideBottom: true,
            isExcelDown: false,
            filtering: false,
            columnSetting: false,
            editable: _vm.editable && !_vm.disabled,
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "customCol"
                    ? [
                        _c(_vm.imprComponent, {
                          tag: "component",
                          attrs: {
                            col: col,
                            props: props,
                            inputEditable: _vm.editable && !_vm.disabled,
                            isImmShow: true,
                            requestContentsCols: _vm.requestContentsCols,
                            tableKey: "causesNo",
                            ibmTaskTypeCd: "ITT0000040",
                            ibmTaskUnderTypeCd: "ITTU000065",
                          },
                          on: { imprChange: _vm.imprChange },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          label: "LBLADD",
                          showLoading: false,
                          icon: "add",
                        },
                        on: { btnClicked: _vm.addImpr },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.disabled &&
                  _vm.accidentInfo.accidentImproveModelList.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          url: _vm.updateCauseUrl,
                          isSubmit: _vm.isSave2,
                          param: _vm.accidentInfo.accidentImproveModelList,
                          mappingType: "PUT",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveImpr,
                          btnCallback: _vm.saveCallback,
                        },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.disabled &&
                  _vm.accidentInfo.accidentImproveModelList.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          label: "LBLREMOVE",
                          showLoading: false,
                          icon: "remove",
                        },
                        on: { btnClicked: _vm.removeImpr },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <q-form ref="editForm">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="사고방지대책 및 원인분석" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline>
            <!-- 기본정보 저장 -->
            <c-btn
              v-show="!disabled"
              :url="updateUrl"
              :isSubmit="isSave"
              :param="accidentInfo"
              mappingType="PUT"
              label="저장"
              icon="save"
              @beforeAction="saveAccident"
              @btnCallback="saveCallback" 
            />
            <c-appr-btn 
              ref="appr-btn"
              name="danger-journal-appr-btn"
              :editable="editable"
              :approvalInfo="approvalInfo"
              @beforeApprAction="saveDataAppr"
              @callbackApprAction="approvalCallback"
              @requestAfterAction="requestAfterAction"
            />
            <!-- <c-btn 
              v-show="editable && !requestDisabled && isOccDept" 
              :isSubmit="isRequest"
              :url="completeUrl"
              :param="accidentInfo"
              mappingType="PUT"
              label="검토요청" 
              icon="check"
              @beforeAction="requestAccident"
              @btnCallback="completeCallback" />
            <c-btn 
              v-show="editable && !approveDisabled && isEnvSafDept" 
              :isSubmit="isApprove"
              :url="completeUrl"
              :param="accidentInfo"
              mappingType="PUT"
              label="승인" 
              icon="check"
              @beforeAction="approveAccident"
              @btnCallback="completeCallback" />
            <c-btn 
              v-show="editable && !returnDisabled && isEnvSafDept" 
              :isSubmit="isReturn"
              :url="completeUrl"
              :param="accidentInfo"
              mappingType="PUT"
              label="반려" 
              icon="front_hand" color="red-3"
              @beforeAction="returnAccident"
              @btnCallback="completeCallback" /> -->
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              label="설비"
              name="equip"
              v-model="accidentInfo.equip"
            >
            </c-textarea>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              label="관리"
              name="management"
              v-model="accidentInfo.management"
            >
            </c-textarea>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              label="교육"
              name="education"
              v-model="accidentInfo.education"
            >
            </c-textarea>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              label="환경"
              name="enviroment"
              v-model="accidentInfo.enviroment"
            >
            </c-textarea>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              label="개인"
              name="individual"
              v-model="accidentInfo.individual"
            >
            </c-textarea>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              label="기타"
              name="etc"
              v-model="accidentInfo.etc"
            >
            </c-textarea>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="5"
              label="사고원인분석"
              name="shortPlan"
              v-model="accidentInfo.shortPlan">
            </c-textarea>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="5"
              label="개선대책"
              name="longPlan"
              v-model="accidentInfo.longPlan">
            </c-textarea>
          </div>
        </template>
      </c-card>
    </div>
    <!-- 개선 목록 -->
    <c-table
      ref="tableImpr"
      title="개선 목록"
      :columns="gridImpr.columns"
      :data="accidentInfo.accidentImproveModelList"
      :gridHeight="gridHeight"
      :merge="gridImpr.merge"
      selection="multiple"
      rowKey="causesNo"
      :usePaging="false"
      :hideBottom="true"
      :isExcelDown="false"
      :filtering="false"
      :columnSetting="false"
      :editable="editable && !disabled"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 추가 -->
          <c-btn v-if="editable && !disabled" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addImpr" />
          <!-- 저장 -->
          <c-btn
            v-if="editable && !disabled && accidentInfo.accidentImproveModelList.length > 0"
            :url="updateCauseUrl"
            :isSubmit="isSave2"
            :param="accidentInfo.accidentImproveModelList"
            mappingType="PUT"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveImpr"
            @btnCallback="saveCallback" 
          />
          <c-btn v-if="editable && !disabled && accidentInfo.accidentImproveModelList.length > 0" label="LBLREMOVE" :showLoading="false" icon="remove" @btnClicked="removeImpr" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="editable&&!disabled"
            :isImmShow="true"
            :requestContentsCols="requestContentsCols"
            tableKey="causesNo"
            ibmTaskTypeCd="ITT0000040"
            ibmTaskUnderTypeCd="ITTU000065"
            @imprChange="imprChange"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'accident-cause-prevention',
  props: {
    param: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
        regUpdateBtnData: {
          title: '사고등록',
          flag: false,
          research: '',
          planResearch: '',
          recResearch: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: true,
      accidentInfo: {
        occurrenceDeptCd: '',
        accidentStatusCd: '',
        accidentCause: '',
        shortPlan: '',
        longPlan: '',
        equip: '',
        management: '',
        education: '',
        enviroment: '',
        individual: '',
        etc: '',
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        accidentImproveModelList: [],
      },
      gridImpr: {
        columns: [
          {
            required: true,
            name: 'causeTypeCd',
            field: 'causeTypeCd',
            // 원인구분
            label: 'LBL00001560',
            align: 'center',
            style: 'width:15%',
            type: 'select',
            comboItems: [
              { code: 'ICDC000001', codeName: '설비' },
              { code: 'ICDC000002', codeName: '관리' },
              { code: 'ICDC000003', codeName: '교육' },
              { code: 'ICDC000003', codeName: '환경' },
              { code: 'ICDC000003', codeName: '개인' },
              { code: 'ICDC000003', codeName: '기타' },
            ],
            sortable: false,
          },
          {
            required: true,
            name: 'accidentCause',
            field: 'accidentCause',
            // 사고원인
            label: 'LBL00001561',
            align: 'left',
            type: 'text',
            style: 'width:50%',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL00001562',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                // 개선번호/진행상태
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:15%',
                type: 'custom',
                sortable: false
              },
              {
                name: 'improvingMeasures',
                field: 'improvingMeasures',
                // 개선대책
                label: 'LBL00001563',
                align: 'left',
                style: 'width:20%',
                type: 'text',
                sortable: false
              },
            ]
          },
        ],
        
        data: [],
        height: '315px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      imprData: null,
      completeUrl: '',
      updateUrl: '',
      updateCauseUrl: '',
      deleteUrl: '',
      searchUrl: '',
      isSave: false,
      isSave2: false,
      isRequest: false,
      isApprove: false,
      isReturn: false,
      getUrl: '',
      isApproval: false,
    };
  },
  computed: {
    
    approvalInfo() {
      return {
        sysApprovalRequestId: this.accidentInfo.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.accidentInfo.approvalStatusCd, 
        apprEditable: this.param.stepCd !== 'ISPC000005' && this.accidentInfo.approvalStatusCd !== 'ASC0000001', // 결재버튼 활성화 기준
        param: this.accidentInfo, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000029', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          iimAccidentId: this.accidentInfo.iimAccidentId,
          isApprContent: true
        },
        approvalRequestName: '안전사고 ['+this.accidentInfo.accidentName+']', // 결재요청명 (문서 title)
        approvalConnId: this.param.iimAccidentId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    gridHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 350
      return numHeight + 'px';
    },
    disabled() {
      return this.param.stepCd === 'ISPC000005' || this.accidentInfo.approvalStatusCd === 'ASC0000001'
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
    // 나중에 바꿔야함 -> 권한별로 
    isEnvSafDept() { 
      return this.$_.indexOf(this.$store.getters.auths, 'SAGI000022') > -1 || this.$store.getters.user.empNo === 'admin'
    },
    isOccDept() { 
      return this.accidentInfo.occurrenceDeptCd === this.$store.getters.user.deptCd
    },
    requestDisabled() {
      return this.disabled || this.accidentInfo.accidentStatusCd === 'ISPC000004'
    },
    approveDisabled() {
      return this.disabled || this.accidentInfo.accidentStatusCd === 'ISPC000003'
    },
    returnDisabled() {
      return this.disabled || this.accidentInfo.accidentStatusCd === 'ISPC000003'
    },
    requestContentsCols() {
      return ['accidentCause'];
    }
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.getUrl = selectConfig.sop.iim.accident.process.prevention.get.url;
      this.updateUrl = transactionConfig.sop.iim.accident.process.prevention.update.url
      this.updateCauseUrl = transactionConfig.sop.iim.accident.process.prevention.update.cause.url
      this.deleteUrl = transactionConfig.sop.iim.accident.process.prevention.delete.url
      this.completeUrl = transactionConfig.sop.iim.accident.process.prevention.complete.url
      this.getDetail();

    },
    getDetail() {
      if (this.param.iimAccidentId) {
        this.$http.url = this.$format(this.getUrl, this.param.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.accidentInfo = _result.data;
        },);
      }
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
    },
    saveAccident() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.accidentInfo.regUserId = this.$store.getters.user.userId
              this.accidentInfo.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveImpr() {
      if(this.$comm.validTable(this.gridImpr.columns, this.accidentInfo.accidentImproveModelList)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.accidentInfo.regUserId = this.$store.getters.user.userId
            this.accidentInfo.chgUserId = this.$store.getters.user.userId
            this.isSave2 = !this.isSave2;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    addImpr() {
      this.accidentInfo.accidentImproveModelList.splice(0, 0, {
        iimAccidentId: this.param.iimAccidentId,
        causesNo: uid(),
        causeTypeCd: null,
        accidentCause: '',
        improvingMeasures: '',
        sopImprovementIds: '', 
        ibmStepNames: '', 
        editFlag: 'C',
      })
    },
    removeImpr() {
      let selectData = this.$refs['tableImpr'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$refs['tableImpr'].$refs['compo-table'].clearSelection();
              this.getDetail();
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    
    requestAccident() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '검토요청하시겠습니까?', 
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.accidentInfo.accidentStatusCd = 'ISPC000004'
          this.accidentInfo.regUserId = this.$store.getters.user.userId
          this.accidentInfo.chgUserId = this.$store.getters.user.userId
          
          this.isRequest = !this.isRequest;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    approveAccident() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '승인하시겠습니까?<br>(종결처리 상태로 변경됩니다.)', 
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.accidentInfo.accidentStatusCd = 'ISPC000005'
          this.accidentInfo.regUserId = this.$store.getters.user.userId
          this.accidentInfo.chgUserId = this.$store.getters.user.userId
          this.isApprove = !this.isApprove;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    returnAccident() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '반려하시겠습니까?<br>(사고등록 상태로 변경됩니다.)', 
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.accidentInfo.accidentStatusCd = 'ISPC000003'
          this.accidentInfo.regUserId = this.$store.getters.user.userId
          this.accidentInfo.chgUserId = this.$store.getters.user.userId
          
          this.isReturn = !this.isReturn;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.accidentInfo.accidentStatusCd) {
        this.$emit('emitStep', {
          name: 'currentStep',
          param: this.accidentInfo.accidentStatusCd 
        })
      }
    },
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.accidentInfo.regUserId = this.$store.getters.user.userId;
          this.accidentInfo.chgUserId = this.$store.getters.user.userId;
          let promises = [
            {
              func: this.approvalValue
            },
          ];
          this.$comm.orderedPromise(promises);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    requestAfterAction() {
      this.getDetail();
      this.param.regUpdateBtnData.recResearch = uid();
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
  }
};
</script>
